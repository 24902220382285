import { useState, useEffect } from 'react'
import { ReactSortable } from "react-sortablejs";
import Chapter from './chapter'
import ChapterForm from './chapter_form'
import LessonForm from './lesson_form'
import CourseSettings from './course_settings'

const CoursesForm = ({course_data, currencies, owner_name, trainers}) => {
  const [course, setCourse] = useState(course_data)
  const [chapters, setChapters] = useState()
  const [activeChapter, setActiveChapter] = useState()
  const [activeLesson, setActiveLesson] = useState()
  const [savedLessonAlert, setSavedLessonAlert] = useState(false);
  const [visibleCourseForm, setVisibleCourseForm] = useState(false)

  useEffect(() => {
    loadChapters()
  }, [])

  const loadChapters = () => {
    $.get({
      url: `/api/courses/${course.id}/chapters`,
      success: (response) => {
        setChapters(response.chapters)
      }
    })
  }

  const addChapter = () => {
    $.post({
      url: `/api/courses/${course.id}/chapters`,
      success: (response) => {
        setChapters([...chapters, response.chapter])
        setActiveChapter(response.chapter)
        setActiveLesson(null)
      }
    })
  }

  const addLesson = (chapter) => {
    $.post({
      url: `/api/courses/${course.id}/chapters/${chapter.id}/lessons`,
      success: (response) => {
        const newChapters = chapters.map(ch => {
          if (ch.id == chapter.id) {
            let newLessons = [...chapter.lessons, response.lesson]
            return Object.assign({}, chapter, {lessons: newLessons})
          } else {
            return ch
          }
        })
        setChapters(newChapters)
        setActiveLesson(response.lesson)
        setActiveChapter(null)
      }
    })
  }

  const updateChapter = (formData) => {
    $.ajax({
      url: `/api/courses/${course.id}/chapters/${activeChapter.id}`,
      method: 'PATCH',
      data: {
        chapter: _.pick(formData, ['name'])
      },
      success: (response) => {
        if (response.errors) {
          alert(response.errors.join('. '))
        } else {
          const newChapters = chapters.map(ch => {
            if (ch.id == activeChapter.id) {
              return response.chapter
            } else {
              return ch
            }
          })
          setChapters(newChapters)
          setActiveChapter(null)
        }
      }
    })
  }

  const updateLesson = (formData) => {
    $.ajax({
      url: `/api/courses/${course.id}/chapters/${activeLesson.chapter_id}/lessons/${activeLesson.id}`,
      method: 'PATCH',
      data: {
        lesson: formData
      },
      success: (response) => {
        if (response.errors) {
          alert(response.errors.join('. '))
        } else {
          const newChapters = chapters.map(ch => {
            if (ch.id == activeLesson.chapter_id) {
              let newLessons = ch.lessons.map(l => {
                if (l.id == activeLesson.id) {
                  return response.lesson
                } else {
                  return l
                }
              })
              return Object.assign({}, ch, {lessons: newLessons})
            } else {
              return ch
            }
          })
          setChapters(newChapters)
          setSavedLessonAlert(true)

          setTimeout(() => {
            setSavedLessonAlert(false)
          }, 2000)
        }
      }
    })
  }

  const updateCourse = (formData) => {
    const finalData = _.pick(formData, ['name', 'hidden_times', 'trainer_id', 'required_phone', 'cert_name_x', 'cert_name_y', 'cert_date_x', 'cert_date_y', 'short_desc', 'desc', 'active', 'pay_method', 'price', 'has_invoices', 'vat_payer', 'price', 'price_with_vat', 'price_mode', 'vat', 'currency', 'video_type', 'external_example_video'])

    $.ajax({
      url: `/api/courses/${course.id}`,
      method: 'PATCH',
      data: {
        course: finalData
      },
      success: (response) => {
        if (response.errors) {
          alert(response.errors.join('. '))
        } else {
          setCourse(response.course)
          setVisibleCourseForm(false)
        }
      }
    })
  }

  function arrayEquals(a, b) {
    return Array.isArray(a) &&
        Array.isArray(b) &&
        a.length === b.length &&
        a.every((val, index) => val === b[index]);
  }

  const sortLessons = (chapter, arr) => {
    const newChapters = chapters.map(ch => {
      if (ch.id == chapter.id) {
        return Object.assign({}, ch, {lessons: arr})
      } else {
        return ch
      }
    })

    if (!arrayEquals(chapter.lessons, arr)) {
      setChapters(newChapters)
      setActiveLesson(null)

      $.ajax({
        url: `/api/courses/${course.id}/chapters/${chapter.id}/lessons/sort`,
        method: 'PATCH',
        data: {
          lessons: arr.map(l => l.id)
        },
        success: () => {
        }
      })
    }
  }

  const sortChapters = (arr) => {
    const newChapters = arr

    if (!arrayEquals(chapters, newChapters)) {
      setChapters(newChapters)
      setActiveLesson(null)
      setActiveChapter(null)

      $.ajax({
        url: `/api/courses/${course.id}/chapters/sort`,
        method: 'PATCH',
        data: {
          chapters: arr.map(l => l.id)
        },
        success: () => {
        }
      })
    }
  }

  const deleteLesson = (lesson) => {
    if (confirm(I18n.t('confirm_delete_lesson', {name: lesson.name}))) {
      $.ajax({
        url: `/api/courses/${course.id}/chapters/${lesson.chapter_id}/lessons/${lesson.id}`,
        method: 'DELETE',
        success: () => {
          if (activeLesson && activeLesson.id == lesson.id) setActiveLesson(null)

          const newChapters = chapters.map(ch => {
            if (ch.id == lesson.chapter_id) {
              return Object.assign({}, ch, {lessons: ch.lessons.filter(l => l.id != lesson.id)})
            } else {
              return ch
            }
          })
          setChapters(newChapters)
        }
      })
    }
  }

  const deleteChapter = (chapter) => {
    if (confirm(I18n.t('confirm_delete_chapter', {name: chapter.name}))) {
      $.ajax({
        url: `/api/courses/${course.id}/chapters/${chapter.id}`,
        method: 'DELETE',
        success: () => {
          if (activeChapter && activeChapter.id == chapter.id) setActiveChapter(null)
          if (activeLesson && chapter.lessons.map(l => l.id).includes(activeLesson.id)) setActiveLesson(null)
          setChapters(chapters.filter(ch => ch.id != chapter.id))
        }
      })
    }
  }

  return (
    <>

      {visibleCourseForm ?
        <CourseSettings
          updateCourse={updateCourse}
          setVisibleCourseForm={setVisibleCourseForm}
          course={course}
          currencies={currencies}
          owner_name={owner_name}
          trainers={trainers}
        /> : null
      }

      {!visibleCourseForm ?
        <div className="row">
          <div className="col-lg-3 col-md-4">
            {chapters && chapters.length ?
              <ReactSortable
                list={chapters}
                tag="div"
                handle=".move"
                setList={(newState) => sortChapters(newState)}
              >
                {chapters.map(ch =>
                  <Chapter
                    key={ch.id}
                    chapter={ch}
                    sortLessons={sortLessons}
                    activeLesson={activeLesson}
                    activeChapter={activeChapter}
                    setActiveChapter={setActiveChapter}
                    setActiveLesson={setActiveLesson}
                    addLesson={addLesson}
                    deleteLesson={deleteLesson}
                    deleteChapter={deleteChapter}
                  />
                )}
              </ReactSortable> : null
            }

            <button className="btn btn-themecolor mr-2 mb-3" onClick={addChapter}>{I18n.t('add_chapter')}</button>
            <button className="btn btn-secondary mb-3" onClick={() => setVisibleCourseForm(true)}>{I18n.t('edit_course')}</button>
          </div>

          <div className="col-lg-9 col-md-8">
            {activeChapter ?
              <ChapterForm
                key={activeChapter.id}
                chapter={activeChapter}
                updateChapter={updateChapter}
                cancel={() => setActiveChapter(null)}
              /> : null
            }
            {activeLesson?
              <LessonForm
                key={activeLesson.id}
                course={course}
                lesson={activeLesson}
                loadChapters={loadChapters}
                updateLesson={updateLesson}
                savedLessonAlert={savedLessonAlert}
                cancel={() => setActiveLesson(null)}
              /> : null
            }
          </div>
        </div> : null
      }
    </>
  )
}

export default CoursesForm