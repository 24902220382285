import { useEffect, useState, useMemo, useRef } from 'react'
import { useForm } from "react-hook-form"
import { Editor } from '@tinymce/tinymce-react';
import { useDropzone } from 'react-dropzone'
import ReactPlayer from 'react-player'

const CourseSettings = ({updateCourse, setVisibleCourseForm, course, currencies, owner_name, trainers}) => {
  let defaultValues = {...course}
  const maxFileSize = 600000000
  const uuid = useMemo(() => Date.now(), [])
  defaultValues.price_mode = course.price_mode || 'with_vat'
  defaultValues.vat = course.vat || 21
  const [files, setFiles] = useState([]);
  const [certUrl, setCertUrl] = useState(course?.cert_url);
  const [imageUrl, setImageUrl] = useState(course?.image_url);
  const [videoUrl, setVideoUrl] = useState(course?.example_video?.url || course?.example_video_url || course.external_example_video);
  const { register, handleSubmit, setValue, watch, formState: { errors } } = useForm({defaultValues: defaultValues})
  const watched = watch()

  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps
  } = useDropzone({
    maxFiles: 1,
    accept: {
      'video/mp4': []
    },
    validator: sizeValidator,
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles);
    }
  });

  const changeDesc = (content) => {
    setValue('desc', content)
  }

  const deleteVideo = () => {
    if (confirm(I18n.t('really_delete_video'))) {
      jQuery.ajax({
        url: `/api/courses/${course.id}/delete_video`,
        method: 'DELETE',
        success: () => {
          setFiles([])
          setVideoUrl(null)
          setValue('external_example_video', null)
        }
      })
    }
  }

  useEffect(() => {
    if (files.length) {
      const file = files[0]
      const formData = new FormData()
      formData.append('video', file)
      formData.append("Content-Type", file.type);

      jQuery.ajax({
        url: `/api/courses/${course.id}/upload_video`,
        dataType: "xml",
        cache: false,
        method: 'POST',
        processData: false,
        contentType: false,
        data: formData,
        xhr: () => {
          var xhr = new window.XMLHttpRequest();
          xhr.upload.addEventListener("progress", function(evt) {
              if (evt.lengthComputable) {
                  var percentComplete = ((evt.loaded / evt.total) * 100);
                  $(".progress-bar").width(percentComplete + '%');
              }
          }, false);
          return xhr;
        },
        success: () => {
          jQuery.ajax({
            url: `/api/courses/${course.id}`,
            success: (response) => {
              setVideoUrl(response.course.example_video_url)
            }
          })
        },
        error: (data) => {
          console.log(data)
        }
      })
    }
  }, [files])

  const uploadCert = (e) => {
    if (!e.target.files[0]) return

    const formData = new FormData()
    formData.append('cert', e.target.files[0])
    formData.append("Content-Type", e.target.files[0].type);

    jQuery.ajax({
      url: `/api/courses/${course.id}/upload_cert`,
      dataType: "xml",
      cache: false,
      method: 'POST',
      processData: false,
      contentType: false,
      data: formData,
      success: () => {
        jQuery.ajax({
          url: `/api/courses/${course.id}`,
          success: (response) => {
            setCertUrl(response.course.cert_url)
          }
        })
      },
      error: (data) => {
        console.log(data)
      }
    })
  }

  const uploadImage = (e) => {
    if (!e.target.files[0]) return

    const formData = new FormData()
    formData.append('image', e.target.files[0])
    formData.append("Content-Type", e.target.files[0].type);

    jQuery.ajax({
      url: `/api/courses/${course.id}/upload_image`,
      dataType: "xml",
      cache: false,
      method: 'POST',
      processData: false,
      contentType: false,
      data: formData,
      success: () => {
        jQuery.ajax({
          url: `/api/courses/${course.id}`,
          success: (response) => {
            setImageUrl(response.course.image_url)
          }
        })
      },
      error: (data) => {
        console.log(data)
      }
    })
  }

  const deleteCert = (e) => {
    e.preventDefault()

    if (confirm(I18n.t('really_delete_cert'))) {
      jQuery.ajax({
        url: `/api/courses/${course.id}/delete_cert`,
        method: 'DELETE',
        success: () => {
          setCertUrl(null)
        }
      })
    }
  }

  const deleteImage = (e) => {
    e.preventDefault()

    if (confirm(I18n.t('really_delete_image'))) {
      jQuery.ajax({
        url: `/api/courses/${course.id}/delete_image`,
        method: 'DELETE',
        success: () => {
          setImageUrl(null)
        }
      })
    }
  }

  function sizeValidator(file) {
    if (file.size > maxFileSize) {
      return {
        code: "file-too-large",
        message: `File is larger ${maxFileSize}`
      };
    }

    return null
  }

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks
    files.forEach(file => URL.revokeObjectURL(file.preview));
  }, [files]);

  return (
    <div className="card">
      <div className="card-header">
        <h4 className="mb-0">{watched?.name}</h4>
      </div>
      <div className="card-body">
        <form onSubmit={handleSubmit(updateCourse)}>
          <div className={`form-group ${errors.name && 'field_with_errors'}`}>
            <label htmlFor="name">{I18n.t('activerecord.attributes.course.name')}</label>
            <input {...register('name', {required: true})} id="name" type="text" className="form-control" />
            {errors.name?.type === "required" && <label className="label label-danger">{I18n.t('simple_form.required.text')}</label>}
          </div>

          {videoUrl ?
            <div className="mb-2">
              <label className="radio_buttons">{I18n.t('activerecord.attributes.course.example_video')}</label>
              <ReactPlayer url={videoUrl + "?time=" + uuid} controls width={'100%'} />

              <div className="mt-2">
                <a href="#" className="btn btn-xs btn-danger" onClick={(e) => !e.preventDefault() && deleteVideo()}>{I18n.t('delete_video')}</a>
              </div>
            </div> :

            <>
              <div className="i-checks radio_buttons">
                <label className="radio_buttons">{I18n.t('activerecord.attributes.course.example_video')}</label>
                <span className="radio">
                  <input {...register('video_type')} className="radio_buttons form-control" type="radio" value="external" id="video_type1" />
                  <label className="collection_radio_buttons" htmlFor="video_type1">{I18n.t('video_types.external')}</label>
                </span>

                <span className="radio">
                  <input {...register('video_type')} className="radio_buttons form-control" type="radio" value="file" id="video_type2"/>
                  <label className="collection_radio_buttons" htmlFor="video_type2">{I18n.t('video_types.file')}</label>
                </span>
              </div>

              {watched?.video_type == 'file' ?
                <section className="mb-2">
                  <div className={`${files && files.length ? 'hide' : ''}`}>
                    <div {...getRootProps({ className: 'dropzone' })}>
                      <input {...getInputProps()} />
                      <p>{I18n.t('dragdrop')}</p>
                      <em>{I18n.t('dragdrop_help')}</em>
                    </div>
                  </div>

                  <aside>
                    {files && files.length ?
                      <>
                        {files.map(file => (
                          <div className="alert alert-info" key={file.path}>
                            <h4>{I18n.t('video_uploading')}</h4>
                            {file.path} - {Math.round(file.size / 1000 / 1000)} MB

                            <div className="progress">
                              <div className="progress-bar" style={{width: '0%'}}></div>
                            </div>
                          </div>
                        ))}
                      </> : null
                    }

                    {fileRejections && fileRejections.length ?
                      <>
                        <h4>Rejected files</h4>
                        {fileRejections.map(({ file, errors }) => (
                          <div className="alert alert-danger" key={file.path}>
                            <span className="label label-danger">{file.path} - {Math.round(file.size / 1000 / 1000)} MB </span>
                            <ul>
                              {errors.map(e => <li key={e.code}>{e.message}</li>)}
                            </ul>
                          </div>
                          )
                        )}
                      </> : null
                    }
                  </aside>
                </section> : null
              }

              {watched?.video_type == 'external' ?
                <div className={`form-group ${errors.external_example_video && 'field_with_errors'}`}>
                  <label htmlFor="external_example_video">{I18n.t('activerecord.attributes.course.external_example_video')}</label>
                  <input {...register('external_example_video')} id="external_example_video" type="text" className="form-control" placeholder="https://" />
                </div> : null
              }
            </>
          }

          <label>{I18n.t('activerecord.attributes.course.desc')}</label>
          <Editor
            tinymceScriptSrc={'https://cdnjs.cloudflare.com/ajax/libs/tinymce/7.0.1/tinymce.min.js'}
            initialValue={course.desc}
            onChange={(x) => changeDesc(x.level.content)}
            init={{
              height: 300,
              language: I18n.locale,
              language_url: `https://dogres.cz/tiny_langs/${I18n.locale}.js`,
              menubar: false,
              statusbar: false,
              setup: function (editor) {
                const openGallery = () => {
                  $.fancybox.open({
                    src: '/trainer/photos',
                    type: 'iframe'
                  })
                };
                editor.ui.registry.addButton("gallery", {
                  icon: 'image',
                  onAction: openGallery
                });
              },
              plugins: [
                'advlist', 'autoresize', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
              ],
              link_default_target: '_blank',
              relative_urls: false,
              convert_urls : false,
              link_assume_external_targets: true,
              toolbar: 'undo redo | blocks | ' +
                'link gallery bold italic forecolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'table code paste | removeformat',
              content_style: 'body { font-family:sans-serif; font-size:14px }'
            }}
          />

          <div className={`form-group ${errors.short_desc && 'field_with_errors'}`}>
            <label htmlFor="short_desc">{I18n.t('activerecord.attributes.course.short_desc')}</label>
            <textarea {...register('short_desc', {maxLength: {value: 100}})} id="short_desc" type="text" className="form-control"></textarea>
            {errors.short_desc?.type === "maxLength" && <label className="label label-danger">{I18n.t('maxlength', {lng: 100})}</label>}
          </div>

          <div className="row">
            <div className="col-md-4">
              <div className={`form-group`}>
                <label htmlFor="image">{I18n.t('activerecord.attributes.course.image')}</label>
                {imageUrl ?
                  <div>
                    <img src={imageUrl} alt='image' style={{width: '150px', height: 'auto'}} />
                    <br/>
                    <small><a href="#" className="text-danger" onClick={deleteImage}>{I18n.t('delete')}</a></small>
                  </div> :
                  <>
                    <input onChange={uploadImage} id="image" type="file" className="form-control" />
                    <small>*.jpg, *.png</small>
                  </>
                }
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <div className={`form-group`}>
                <label htmlFor="cert">{I18n.t('activerecord.attributes.course.cert')}</label>
                {certUrl ?
                  <div>
                    <img src={certUrl} alt='cert' style={{width: '150px', height: 'auto'}} />
                    <br/>
                    <small><a href="#" className="text-danger" onClick={deleteCert}>{I18n.t('delete')}</a></small>
                  </div> :
                  <>
                    <input onChange={uploadCert} id="cert" type="file" className="form-control" />
                    <small>*.jpg, *.png</small>
                  </>
                }
              </div>
            </div>
            {certUrl ?
              <>
                <div className="col-md-4">
                  <label htmlFor="cert_name_x">{I18n.t('activerecord.attributes.course.cert_name')}</label>
                  <div className="input-group">
                    <input {...register('cert_name_x')} id="cert_name_x" type="number" className="form-control" placeholder="X" />
                    <input {...register('cert_name_y')} id="cert_name_y" type="number" className="form-control" placeholder="Y" />
                  </div>
                  <a href={`/trainer/courses/${course.id}/example_cert.pdf`} target="_blank">{I18n.t('show_example_cert')}</a>
                </div>
                <div className="col-md-4">
                  <label htmlFor="cert_date_x">{I18n.t('activerecord.attributes.course.cert_date')}</label>
                  <div className="input-group">
                    <input {...register('cert_date_x')} id="cert_date_x" type="number" className="form-control" placeholder="X" />
                    <input {...register('cert_date_y')} id="cert_date_y" type="number" className="form-control" placeholder="Y" />
                  </div>
                </div>
              </> : null
            }
          </div>

          <div className="i-checks boolean optional mt-2">
            <input className="boolean optional form-control" type="checkbox" id="active" {...register('active')} />
            <label className="boolean optional" htmlFor="active">{I18n.t('activerecord.attributes.course.active')}</label>
          </div>

          <div className="i-checks boolean optional mt-2">
            <input className="boolean optional form-control" type="checkbox" id="hidden_times" {...register('hidden_times')} />
            <label className="boolean optional" htmlFor="hidden_times">{I18n.t('activerecord.attributes.course.hidden_times')}</label>
          </div>

          <div className="input select mt-2">
            <label htmlFor="trainer_id">{I18n.t('activerecord.attributes.course.trainer_id')}</label>
            <select className="select form-control" id="trainer_id" {...register('trainer_id')}>
              <option value="">{owner_name}</option>
              {trainers.map(tr =>
                <option key={tr.id} value={tr.id}>{tr.name}</option>
              )}
            </select>
          </div>

          <div className="input select mt-2">
            <label htmlFor="pay_method">{I18n.t('activerecord.attributes.course.pay_method')}</label>
            <select className="select form-control" id="pay_method" {...register('pay_method', {required: true})}>
              {['cash', 'account'].map(pt =>
                <option key={pt} value={pt}>{I18n.t(`enums.pay_methods.${pt}`)}</option>
              )}
            </select>
            {errors.pay_method?.type === "required" && <label className="label label-danger">{I18n.t('simple_form.required.text')}</label>}
          </div>

          <br/>

          <div className="price_boxed">
            <label htmlFor="price">{I18n.t('activerecord.attributes.course.price')}</label>
            <div className="input-group">
              {watched?.vat_payer ?
                <select className="select form-control" id="price_mode" {...register('price_mode', {required: watched?.vat_payer})}>
                  {['with_vat', 'without_vat'].map(pt =>
                    <option key={pt} value={pt}>{I18n.t(`enums.price_modes.${pt}`)}</option>
                  )}
                </select> : null
              }

              {!watched?.vat_payer || (watched?.vat_payer && watched?.price_mode == 'without_vat') ?
                <input {...register('price', {required: !watched?.vat_payer || (watched?.vat_payer && watched?.price_mode == 'without_vat')})} id="price" type="number" step="0.01" className="form-control" /> : null
              }

              {watched?.vat_payer && watched?.price_mode == 'with_vat' ?
                <input {...register('price_with_vat', {required: watched?.vat_payer && watched?.price_mode == 'with_vat'})} id="price_with_vat" type="number" step="0.01" className="form-control" /> : null
              }

              <select className="select form-control" id="currency" {...register('currency', {required: true})}>
                {currencies.map(c =>
                  <option key={c} value={c}>{I18n.t(`enums.currencies.${c}`)}</option>
                )}
              </select>

            </div>

            {errors.price?.type === "required" || errors.price_with_vat?.type === "required" || errors.currency?.type === "required" ?
              <label className="label label-danger">{I18n.t('simple_form.required.text')}</label> : null
            }
          </div>

          <div className="i-checks boolean optional mt-2">
            <input className="boolean optional form-control" type="checkbox" id="vat_payer" {...register('vat_payer')} />
            <label className="boolean optional" htmlFor="vat_payer">{I18n.t('activerecord.attributes.training.vat_payer')}</label>
          </div>

          <div className="i-checks boolean optional mt-2">
            <input className="boolean optional form-control" type="checkbox" id="has_invoices" {...register('has_invoices')} />
            <label className="boolean optional" htmlFor="has_invoices">{I18n.t('activerecord.attributes.training.has_invoices')}</label>
          </div>

          {watched?.vat_payer ?
            <div className={`form-group ${errors.vat && 'field_with_errors'}`}>
              <label htmlFor="vat">{I18n.t('activerecord.attributes.training.vat')}</label>
              <input {...register('vat', {required: watched?.vat_payer})} id="vat" type="number" className="form-control" />
              {errors.vat?.type === "required" && <label className="label label-danger">{I18n.t('simple_form.required.text')}</label>}
            </div> : null
          }

          <div className="i-checks boolean optional mt-2">
            <input className="boolean optional form-control" type="checkbox" id="required_phone" {...register('required_phone')} />
            <label className="boolean optional" htmlFor="required_phone">{I18n.t('activerecord.attributes.course.required_phone')}</label>
          </div>

          <div className="mt-2">
            <button type="submit" className="btn btn-themecolor">{I18n.t('save')}</button>
            <button className="btn btn-secondary ml-2" onClick={() => setVisibleCourseForm(false)}>{I18n.t('back')}</button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default CourseSettings